import { IBloatedGrantee, IProposal } from '.';
import { ECommitmentType, ECommitmentReason, EFundOrigin } from '../enums/commitment.enum';
import { IApplication } from './IApplications.interfaces';
import { IFunder, IFunderTag } from './IFunder';
import { IJFGridQuery } from './jf-grid.interface';

export interface ICommitment {
  total_funded: number;
  id: number;
  is_anonymous_to_funders: boolean;
  is_anonymous_to_grantee: boolean;
  grantee_id: number;
  grantee?: IBloatedGrantee;
  funder_id: number;
  amount: number;
  format: string;
  comment: string;
  application_id?: number;
  grant_type?: ECommitmentReason;
  project_purpose?: string;
  proposal_id?: number;
  creator_user_id: number;
  created_at: string;
  committed: string;
  paid?: string;
  type: ECommitmentType;
  origin?: EFundOrigin;
  modified_at: string;
  funder: IFunder;
  application: IApplication;
  proposal: IProposal | undefined;
  reason: ECommitmentReason;
  isNotifiable?: boolean;
  commitmentTag: IFunderTag[];
}

export interface ICommitmentsQuery extends IJFGridQuery {}

export type CommitmentsResponse = {
  grantCycleCommitments: ICommitment[];
  networkCommitments: ICommitment[];
};

export type OrgFundResponse = {
  orgFundCommitments: ICommitment[];
};

export interface IFundCommitments {
  grantCycleCommitments?: ICommitment[];
  networkCommitments?: ICommitment[];
  orgFundCommitments?: ICommitment[];
}

export enum Format {
  CHECK = 'check',
  ONLINE = 'online',
  STOCK = 'stock',
  ACH = 'ach',
  WIRE = 'wire',
  OTHER = 'other'
}
