// utils
import { theme } from '../../util/util';
import { AlertPosition } from './Notification';

export const classes = {
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFFFFF',
    boxShadow: theme.shadows[5],
    width: '75vw',
    height: '75vh'
  },
  border: {
    borderTop: '1px solid #979797',
    width: '100%'
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: 0,
      border: ' 1px solid #000000',
      boxShadow: 'inset 0 2px 00 #ffffff'
    }
  }
};

export const alertStyles = {
  alert: {
    zIndex: 2000,
    position: 'fixed',
    transition: 'fade',
    minWidth: 300,
    maxWidth: 600,
    borderRadius: '0.5rem'
  },
  alertTitle: {
    fontSize: '1rem',
    fontWeight: 'bold'
  }
};

export const getPositionStyles = (position: AlertPosition) =>
  ({
    'top-right': {
      top: '20px',
      right: '20px'
    },
    'top-left': {
      top: '20px',
      left: '20px'
    },
    'bottom-right': {
      bottom: '20px',
      right: '20px'
    },
    'bottom-left': {
      bottom: '20px',
      left: '20px'
    },
    'bottom-center': {
      bottom: '20px',
      left: '50%',
      transform: 'translateX(-50%)'
    }
  }[position]);
