export enum ECommitmentType {
  ON_BEHALF_GRANT_CYCLE = 0,
  ON_BEHALF_FUNDER = 2
}

export enum ECommitmentReason {
  NONE = 0,
  GENERAL = 1,
  PROGRAM_SUPPORT = 2,
  OTHER = 3
}

export enum EFundOrigin {
  PROPOSAL = 0,
  APPLICATION = 1,
  PROFILE = 2,
  SUBMISSION_TABLE = 3
}
