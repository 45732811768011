export enum RFPStatus {
  APPROVED,
  SUBMITTED,
  DRAFT
}

export enum RFPStatusLabel {
  APPROVED = 'Approved',
  SUBMITTED = 'Submitted',
  DRAFT = 'Draft',
  ACTIVE = 'Active',
  CLOSED = 'Closed',
  DELETED = 'Deleted'
}

export const RFPStatusMap = {
  [RFPStatus.APPROVED]: 'Approved',
  [RFPStatus.DRAFT]: 'Draft',
  [RFPStatus.SUBMITTED]: 'Submitted'
};
