import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertTitle, Box, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useMessagingContext } from './Notification';
import { alertStyles, getPositionStyles } from './styles';

export const AlertComponent = () => {
  const {
    alertState: {
      title,
      message,
      severity,
      onClose,
      position = 'top-right',
      action
    },
    resetAlert
  } = useMessagingContext();
  // TODO some weird CSS preventing this from showing correctly but mechanisms are in place
  const [show, setShow] = useState(false);
  useEffect(() => {
    let shouldShow = false;
    if (title || message) {
      shouldShow = true;
      setTimeout(() => {
        resetAlert();
        setShow(false);
        onClose?.();
      }, 3000);
    }
    setShow(shouldShow);
  }, [title, message]);

  return (
    <>
      {show && (
        <Alert
          onClose={() => {
            resetAlert();
            onClose?.();
          }}
          severity={severity}
          variant="filled"
          action={
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              {action}
              <IconButton
                color="inherit"
                size="small"
                onClick={() => {
                  resetAlert();
                  onClose?.();
                }}
              >
                <CloseIcon sx={{ fontSize: '1.375rem' }} />
              </IconButton>
            </Box>
          }
          sx={{ ...alertStyles.alert, ...getPositionStyles(position) } as any}
        >
          <AlertTitle style={alertStyles.alertTitle}>{title}</AlertTitle>
          {message}
        </Alert>
      )}
    </>
  );
};
